body,
html {
  padding-left: 0;
  overflow: hidden; /* Görgetés tiltása */
  background-color: black;
  color: white;
}
.col-md-6 {
  padding: 0;
}
.irGa-tbl tr td {
  background: black;
}
